import moment from "moment";
import firebase from "firebase/compat/app";
import { parsePhoneNumber } from "./phoneNumbersUtils";
import { db } from "firebaseConfig";
import { GenericConverter } from "utils/firestoreConverters";
import { FavoriteListingsOfContact, ListingsRealtorBlacklist } from "../../@types/sharedSchema";

export function parseFirestoreDocument(obj: firebase.firestore.DocumentData) {
  // Converting firestore timestamp to Date
  const newObj = Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [
      k,
      v instanceof firebase.firestore.Timestamp ? v.toDate() : v
    ])
  );

  return newObj;
}

export function getUpdatedTimeFromFirestoreDoc(doc: any) {
  const seconds = doc?._delegate?._document?.version?.timestamp?.seconds;
  const dateObj = new Date(seconds * 1000);
  if (moment(dateObj)?.isBefore(moment().subtract(40, "years"))) {
    return null;
  } else {
    return dateObj;
  }
}

export function removeUndefined(data) {
  Object.keys(data).forEach((key) => data[key] === undefined && delete data[key]); //filtering out 'undefined'
}

export function generateMetadataFromContact(email: string, contactId: string) {
  const _metadata = {
    callerPhoneNumber: parsePhoneNumber(contactId),
    firestorePath: `dashboard-users-v2/${email}/contacts/${contactId}`
  };

  return _metadata;
}

export function getFavoritesListingsQuery(userEmail: string, contactId: string) {
  return db
    .collection(`favorite-listings-of-contact`)
    .where("realtorId", "==", userEmail)
    .where("contactId", "==", contactId)
    .withConverter(GenericConverter<FavoriteListingsOfContact>());
}

// TODO - add an index for this
export function getContactsThatHaveListingInFavoritesQuery(userEmail: string, listingId: string) {
  return db
    .collection(`favorite-listings-of-contact`)
    .where("realtorId", "==", userEmail)
    .where("listingIds", "array-contains", listingId)
    .withConverter(GenericConverter<FavoriteListingsOfContact>());
}

export const createFavoriteIfNotExists = async (userEmail: string, contactId: string) => {
  try {
    const contactSnapshot = await getFavoritesListingsQuery(userEmail, contactId).get();

    if (contactSnapshot.empty) {
      console.log("No favorite listings found for this contact and user. Will create one...");

      const newFavorite: FavoriteListingsOfContact = {
        contactId: contactId,
        realtorId: userEmail,
        listingIds: [],
        listingIdsUserLiked: [],
        listingIdsUserDisliked: [],
        listingIdsWithPicturesHidden: []
      };

      const newContactSnapshot = await db
        .collection(`favorite-listings-of-contact`)
        .add(newFavorite);

      return newContactSnapshot.id;
    }

    const favoriteContactId = contactSnapshot.docs[0].id;

    return favoriteContactId;
  } catch (error) {
    console.error("Error creating favorite listings for contact", error);
  }
};

export const createFavoriteIfNotExistsAndAddListingToFavorites = async (
  userEmail: string,
  contactId: string,
  listingId: string
) => {
  await createFavoriteIfNotExists(userEmail, contactId);
  const favoriteDocs = await getFavoritesListingsQuery(userEmail, contactId).get();
  if (favoriteDocs.empty) {
    console.error("Got empty favorites after creation, should not happen");
    return;
  }

  const favoriteDoc = favoriteDocs.docs[0];
  await addListingToFavorites(favoriteDoc.ref.path, listingId);

  return favoriteDoc.id;
};

export const addListingToFavorites = async (favoritePath: string, listingId: string) => {
  console.log("About to add listing to favorites", listingId);

  if (!favoritePath) {
    console.warn(
      "No existing favorites found for this contact and user... Should not happen, we have it set above"
    );
    return;
  }

  console.log("Adding listing to existing favorites", listingId);

  let update: Partial<FavoriteListingsOfContact> = {
    listingIds: firebase.firestore.FieldValue.arrayUnion(listingId) as any as string[]
  };

  await db
    .doc(favoritePath)
    .withConverter(GenericConverter<FavoriteListingsOfContact>())
    .update(update);
};

export const removeListingFromFavorites = async (favoritePath: string, listingId: string) => {
  console.log("Removing listing from favorites", listingId);
  if (favoritePath) {
    await db
      .doc(favoritePath)
      .withConverter(GenericConverter<FavoriteListingsOfContact>())
      .update({
        listingIds: firebase.firestore.FieldValue.arrayRemove(listingId)
      });
  }
};

export const addListingPicturesToHiddenList = async (favoritePath: string, listingId: string) => {
  console.log("Add listing pictures to be hidden", listingId);

  if (!favoritePath) return;

  await db

    .doc(favoritePath)
    .withConverter(GenericConverter<FavoriteListingsOfContact>())
    .update({
      listingIdsWithPicturesHidden: firebase.firestore.FieldValue.arrayUnion(listingId)
    });
};

export const removeListingPicturesFromHiddenList = async (
  favoritePath: string,
  listingId: string
) => {
  console.log("Remove listing pict", listingId);

  if (!favoritePath) return;

  await db
    .doc(favoritePath)
    .withConverter(GenericConverter<FavoriteListingsOfContact>())
    .update({
      listingIdsWithPicturesHidden: firebase.firestore.FieldValue.arrayRemove(listingId)
    });
};

export const getRealtorListingsBlacklistQuery = (realtorId: string) => {
  return db
    .collection(`listings-realtor-blacklist`)
    .doc(realtorId)
    .withConverter(GenericConverter<ListingsRealtorBlacklist>());
};

export const addListingToRealtorBlackList = async (realtorId: string, listingId: string) => {
  console.log("About to add listing to realtor blacklist", listingId);

  const blacklistPath = db
    .collection(`listings-realtor-blacklist`)
    .doc(realtorId)
    .withConverter(GenericConverter<ListingsRealtorBlacklist>());

  await blacklistPath.set(
    {
      realtorId: realtorId,
      listingIds: firebase.firestore.FieldValue.arrayUnion(listingId) as any as string[]
    },
    { merge: true }
  );
};

export const removeListingFromRealtorBlackList = async (realtorId: string, listingId: string) => {
  console.log("About to remove listing from realtor blacklist", listingId);

  const blacklistPath = db
    .collection(`listings-realtor-blacklist`)
    .doc(realtorId)
    .withConverter(GenericConverter<ListingsRealtorBlacklist>());

  await blacklistPath.set(
    {
      realtorId: realtorId,
      listingIds: firebase.firestore.FieldValue.arrayRemove(listingId) as any as string[]
    },
    { merge: true }
  );
};
