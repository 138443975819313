import {
  ListItem,
  TextField,
  Autocomplete,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  FilterOptionsState,
  createFilterOptions
} from "@material-ui/core";
import useApp from "hooks/useApp";
import { getFullName } from "utils/Haim/utils";
import { useTranslation } from "react-i18next";
import { parsePhoneNumber } from "utils/Haim/phoneNumbersUtils";
import { ContactTableAvatar } from "../contacts/table/rows/ContactTableAvatar";
import type { TFunction } from "react-i18next";
import type { SyntheticEvent } from "react";
import type { RealEstateContact } from "../../../@types/sharedSchema";
import type {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState
} from "@material-ui/core";

type RealEstateContactWithHint = RealEstateContact & { hint?: boolean };

type NewContactPickerProps = {
  loading?: boolean;
  selectedContact: RealEstateContact | null;
  onChangeAction?: (selectedContact?: RealEstateContact) => void;
  setSelectedContact: (contact: RealEstateContact | null) => void;
};

export function NewContactPicker({
  loading,
  selectedContact,
  onChangeAction,
  setSelectedContact
}: NewContactPickerProps): JSX.Element {
  const { t: translate } = useTranslation();

  const { isLoadingContacts, getAllContacts } = useApp();

  const handleSelectedContactChange = (
    _event: SyntheticEvent,
    newContact: null | RealEstateContactWithHint
  ) => {
    const newParsedContact = parseNewContact(newContact);

    setSelectedContact(newParsedContact);
  };

  const allContacts = getAllContacts();

  const showLoading = loading || isLoadingContacts;

  return (
    <Autocomplete
      freeSolo
      fullWidth
      blurOnSelect
      value={selectedContact ?? null}
      options={allContacts}
      disabled={showLoading}
      onChange={handleSelectedContactChange}
      renderInput={handleRenderInput(translate, showLoading)}
      renderOption={handleRenderOption(translate)}
      filterOptions={handleFilterOptions}
      getOptionLabel={handleGetOptionLabel(allContacts)}
      isOptionEqualToValue={handleIsOptionEqualToValue}
    />
  );
}

type NewMultipleContactPickerProps = {
  loading?: boolean;
  selectedContacts: RealEstateContact[];
  onChangeAction?: (selectedContacts?: RealEstateContact[]) => void;
  setSelectedContacts: (contact: RealEstateContact[]) => void;
};

export function NewMultipleContactPicker({
  loading,
  selectedContacts,
  onChangeAction,
  setSelectedContacts
}: NewMultipleContactPickerProps): JSX.Element {
  const { t: translate } = useTranslation();

  const { isLoadingContacts, getAllContacts } = useApp();

  const handleSelectedContactsChange = (
    _event: SyntheticEvent,
    newContacts: RealEstateContactWithHint[]
  ) => {
    const newParsedContacts = newContacts
      .map((newContact) => parseNewContact(newContact, selectedContacts))
      .filter(Boolean);

    setSelectedContacts(newParsedContacts);

    onChangeAction?.(newParsedContacts);
  };

  const allContacts: RealEstateContactWithHint[] = getAllContacts();

  const showLoading = loading || isLoadingContacts;

  return (
    <Autocomplete
      freeSolo
      multiple
      fullWidth
      blurOnSelect
      value={selectedContacts}
      options={allContacts}
      disabled={showLoading}
      onChange={handleSelectedContactsChange}
      renderInput={handleRenderInput(translate, showLoading)}
      renderOption={handleRenderOption(translate)}
      filterOptions={handleFilterOptions}
      getOptionLabel={handleGetOptionLabel(allContacts)}
      isOptionEqualToValue={handleIsOptionEqualToValue}
    />
  );
}

function parseNewContact(
  newContact: string | RealEstateContactWithHint,
  selectedContacts?: RealEstateContact[]
): RealEstateContact {
  let newParsedContact: RealEstateContact | null = null;

  /**
   * New option can be from two sources:
   * - A string if from a solo input and using enter to add a new contact
   * - An object if selected via add a new contact option, this object will have a hint property
   */
  const isNewOption = typeof newContact === "string" || newContact?.hint;

  if (isNewOption) {
    let parsedPhoneNumber: string;

    if (typeof newContact === "string") {
      parsedPhoneNumber = newContact;
    } else {
      parsedPhoneNumber = newContact.callerPhoneNumber;
    }

    const validPhoneNumber = parsePhoneNumber(parsedPhoneNumber);

    if (validPhoneNumber) {
      const parsedInternationalPhoneNumber = validPhoneNumber.format("E.164");

      const isExistingContact = selectedContacts?.some(
        ({ callerPhoneNumber }) => callerPhoneNumber === parsedInternationalPhoneNumber
      );

      if (isExistingContact) return null;

      newParsedContact = {
        id: parsedInternationalPhoneNumber,
        callerPhoneNumber: parsedInternationalPhoneNumber
      } as RealEstateContact;
    }
  } else {
    newParsedContact = newContact;
  }

  return newParsedContact;
}

const filterOptions = createFilterOptions<RealEstateContactWithHint>({
  limit: 100,
  matchFrom: "any",
  stringify: ({ callerFirstName, callerLastName, callerPhoneNumber }) =>
    getFullName(callerFirstName, callerLastName) + callerPhoneNumber
});

function handleFilterOptions(
  options: RealEstateContactWithHint[],
  param: FilterOptionsState<RealEstateContactWithHint>
): RealEstateContactWithHint[] {
  // Sort all options by hot customers first
  options.sort((a, b) => {
    if (a.customerSeriousness === "לקוח חם") return -1;
    if (b.customerSeriousness === "לקוח חם") return 1;

    return 0;
  });

  // Remove leading 0 if searching by local phone number
  param.inputValue = param.inputValue.replace(/^0(\d{2})/, "$1");

  const filteredOptions = filterOptions(options, param);

  const trimmedInputValue = param.inputValue.trim();
  const validPhoneNumber = parsePhoneNumber(trimmedInputValue);

  const isExistingPhoneNumber = options.some(({ callerPhoneNumber }) => {
    const isSimpleSamePhoneNumber = callerPhoneNumber === trimmedInputValue;
    const isSamePhoneNumberAsValid = validPhoneNumber?.format("E.164") === callerPhoneNumber;

    const isSamePhoneNumber = isSimpleSamePhoneNumber || isSamePhoneNumberAsValid;

    return isSamePhoneNumber;
  });

  const addNewContactOption =
    !filteredOptions.length && !isExistingPhoneNumber && trimmedInputValue;

  if (addNewContactOption) {
    const newOption = {
      id: trimmedInputValue,
      hint: true,
      callerPhoneNumber: trimmedInputValue
    } as RealEstateContactWithHint;

    filteredOptions.push(newOption);
  }

  return filteredOptions;
}

function handleRenderOption(translate: TFunction) {
  return (
    props: React.HTMLAttributes<HTMLLIElement>,
    {
      hint,
      callerLastName,
      callerFirstName,
      callerPhoneNumber,
      customerSeriousness
    }: RealEstateContactWithHint,
    _state: AutocompleteRenderOptionState
  ): JSX.Element => {
    const callerFullName = getFullName(callerFirstName, callerLastName);

    const validPhoneNumber = parsePhoneNumber(callerPhoneNumber);

    const formattedLocalPhoneNumber = validPhoneNumber?.formatNational() ?? callerPhoneNumber;

    const isNewOptionHasInvalidPhoneNumber = hint && !validPhoneNumber;

    return (
      <ListItem {...props} disabled={isNewOptionHasInvalidPhoneNumber} key={callerPhoneNumber}>
        <ListItemAvatar>
          <ContactTableAvatar
            badgeSize={16}
            avatarSize={32}
            callerFullName={callerFullName}
            customerSeriousness={customerSeriousness}
          />
        </ListItemAvatar>
        {hint ? (
          validPhoneNumber ? (
            <ListItemText
              primary={`${translate(
                "listingsMatch.contactPicker.add"
              )} ${formattedLocalPhoneNumber}`}
            />
          ) : (
            <ListItemText
              primary={`"${callerPhoneNumber}" ${translate(
                "listingsMatch.contactPicker.isNotAValidPhoneNumber"
              )}`}
            />
          )
        ) : (
          <ListItemText
            primary={callerFullName || formattedLocalPhoneNumber}
            secondary={callerFullName ? formattedLocalPhoneNumber : undefined}
          />
        )}
      </ListItem>
    );
  };
}

function handleRenderInput(translate: TFunction, loading: boolean) {
  return (params: AutocompleteRenderInputParams): JSX.Element => {
    return (
      <TextField
        {...params}
        label={translate("listingsMatch.contactPicker.title")}
        InputProps={{
          ...params.InputProps,
          endAdornment: loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            params.InputProps.endAdornment
          )
        }}
      />
    );
  };
}

function handleGetOptionLabel(contacts: RealEstateContact[]) {
  return (contact: RealEstateContact): string => {
    const contactFromMyContacts = contacts.find(
      ({ callerPhoneNumber }) => callerPhoneNumber === contact.callerPhoneNumber
    );

    const { callerFirstName, callerLastName, callerPhoneNumber } = {
      ...contact,
      ...contactFromMyContacts
    };

    const fullName = getFullName(callerFirstName, callerLastName);

    const phoneNumber = callerPhoneNumber;

    const optionLabel = fullName || phoneNumber || "";

    return optionLabel;
  };
}

function handleIsOptionEqualToValue(option: RealEstateContact, value: RealEstateContact): boolean {
  return option.callerPhoneNumber === value.callerPhoneNumber;
}
