// export let WEBOT_SERVER_ENDPOINT = "http://localhost:8080";
const retry = require("async-retry");

export type WebotConfig = {
  currentDomainServer: string;
};

export async function fetchConfig(): Promise<WebotConfig> {
  return await retry(
    async () => {
      console.log("Fetching webot config...");
      const response = await fetch(
        "https://webotserver.blob.core.windows.net/public/webotConfig.json",
        { cache: "no-cache" } // To get the latest version
      );
      const data = await response.json();
      return data;
    },
    {
      retries: 5,
      minTimeout: 1000
    }
  );
}

export async function getWebotServerDomain(): Promise<string> {
  let webotServerDomain = (await fetchConfig()).currentDomainServer;
  console.log("Got Webot server domain:", webotServerDomain);
  return webotServerDomain;
}

export let WEBOT_SERVER_ENDPOINT = "https://webot-bot.ew.r.appspot.com";

(async () => {
  if (!WEBOT_SERVER_ENDPOINT) {
    let webotServerDomain = await getWebotServerDomain();

    WEBOT_SERVER_ENDPOINT = `https://${webotServerDomain}`;

    console.log("process.env.REACT_APP_USE_LOCAL_WEBOT_ENDPOINT", process.env);

    const isRunningOnPreview = process.env.REACT_APP_FIREBASE_PROJECT_ID === "webot-dev";
    const isRunningUsingLocalServer = process.env.REACT_APP_USE_LOCAL_WEBOT_ENDPOINT === "true";

    if (isRunningOnPreview) {
      WEBOT_SERVER_ENDPOINT = `https://webot-dev.ew.r.appspot.com`;
    }

    if (isRunningUsingLocalServer) {
      WEBOT_SERVER_ENDPOINT = `http://localhost:8080`;
    }

    console.log("Using webot server endpoint:", WEBOT_SERVER_ENDPOINT);
  }
})();

export const MAX_MESSAGES_IN_FREE_TRIAL = 100;
export const SUPPORT_CENTER_PHONE_NUMBER = "0509992209";
export const SUPPORT_CENTER_PHONE_NUMBER_INTL = "+972509992209";
export const SUPPORT_CENTER_PHONE_NUMBER_TEL_URL = `tel:${SUPPORT_CENTER_PHONE_NUMBER_INTL}`;

export const BANNED_NUMBERS = [
  "+972586294352", // Kiril
  "+972525975730", // Molari
  "+972528907687", // Keren Levi
  "+972528071703",
  "+972533380073", // from Alex
  "+972547838385", // she threatened Ofer kleinman (tel aviv)
  "+972546847204", // עמית וורגפט
  "+972534626311" // want to sue customers
];
