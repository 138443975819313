import { Avatar, Box } from "@material-ui/core";
import { stringAvatar } from "utils/contacts/display";
import { SxProps } from "@material-ui/system";
import { CustomerSeriousness } from "../../../../../@types/sharedSchema";

type ContactTableAvatarProps = {
  sx?: SxProps;
  badgeSize: number;
  avatarUrl?: string;
  avatarSize: number;
  callerFullName: string;
  customerSeriousness?: CustomerSeriousness;
};

export function ContactTableAvatar({
  sx,
  badgeSize,
  avatarUrl,
  avatarSize,
  callerFullName,
  customerSeriousness
}: ContactTableAvatarProps): JSX.Element {
  const { sx: avatarStyle, children: avatarText } = stringAvatar(callerFullName);

  const isHotCustomer = customerSeriousness === "לקוח חם";

  return (
    <Box sx={{ position: "relative" }}>
      <Avatar
        sx={{
          ...avatarStyle,
          ...sx,
          width: avatarSize,
          height: avatarSize,
          color: "white"
        }}
        src={avatarUrl}
      >
        {avatarText}
      </Avatar>
      {isHotCustomer && (
        <Box
          sx={{
            position: "absolute",
            bottom: -8,
            right: -8,
            fontSize: badgeSize
          }}
        >
          🔥
        </Box>
      )}
    </Box>
  );
}
