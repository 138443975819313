import * as Yup from "yup";
import { merge } from "lodash";
import { isBefore } from "date-fns";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack5";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useFormik, Form, FormikProvider } from "formik";
import "rc-time-picker/assets/index.css";
import { roundToNearestMinutes, addHours } from "date-fns";
import {
  Box,
  Stack,
  Button,
  Tooltip,
  TextField,
  IconButton,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { LoadingButton, MobileDatePicker, MobileTimePicker } from "@material-ui/lab";
import { EventInput } from "@fullcalendar/common";
import ColorSinglePicker from "../../ColorSinglePicker";
import moment from "moment-timezone";
import { parsePhoneNumber } from "utils/Haim/phoneNumbersUtils";
import {
  CalendarEvent,
  RealEstateContact,
  CalendarEventAttendee
} from "../../../@types/sharedSchema";
import { useTranslation } from "react-i18next";
import { NewMultipleContactPicker } from "../listingsMatch/NewContactPicker";

// ----------------------------------------------------------------------

const COLOR_OPTIONS = [
  "#00AB55", // theme.palette.primary.main,
  "#1890FF", // theme.palette.info.main,
  "#94D82D", // theme.palette.success.main,
  "#FFC107", // theme.palette.warning.main,
  "#FF4842", // theme.palette.error.main
  "#04297A", // theme.palette.info.darker
  "#7A0C2E" // theme.palette.error.darker
];

const getInitialValues = (event: EventInput | null) => {
  // eslint-disable-next-line no-underscore-dangle
  const _event = {
    title: "",
    description: "",
    textColor: "#1890FF",
    allDay: false,
    start: (event?.start ?? roundToNearestMinutes(new Date(), { nearestTo: 15 })) as Date,
    end: (event?.end ?? addHours(roundToNearestMinutes(new Date(), { nearestTo: 15 }), 1)) as Date,
    attendees: []
  };

  if (event) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

type CalendarFormProps = {
  event: EventInput;
  onUpdate: (CalendarEvent) => Promise<boolean>;
  onDelete: (eventId: string) => Promise<void>;
  onCancel: VoidFunction;
};

export default function CalendarForm({ event, onUpdate, onDelete, onCancel }: CalendarFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const isCreating = !event;
  const { t } = useTranslation();

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required("Title is required"),
    description: Yup.string().max(5000)
  });

  const formik = useFormik({
    initialValues: getInitialValues(event),
    validationSchema: EventSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const parsedContactsToAttendees: CalendarEventAttendee[] = values.attendees.map(
          ({
            id,
            lastName,
            firstName,
            callerLastName,
            callerFirstName,
            callerPhoneNumber,
            phoneNumberInternational
          }) => {
            let parsedAttendee: CalendarEventAttendee = {
              lastName,
              firstName,
              phoneNumberInternational
            };

            const validPhoneNumber = parsePhoneNumber(callerPhoneNumber)?.format("E.164") ?? id;

            if (validPhoneNumber) {
              parsedAttendee.phoneNumberInternational = validPhoneNumber;
            }

            if (callerFirstName) {
              parsedAttendee.firstName = callerFirstName;
            }

            if (callerLastName) {
              parsedAttendee.lastName = callerLastName;
            }

            return parsedAttendee;
          }
        );

        const newEvent: CalendarEvent = {
          id: event.id,
          title: values.title,
          description: values.description,
          backgroundColor: values.textColor,
          textColor: values.textColor,
          allDay: values.allDay,
          start: values.start,
          end: values.end,
          attendees: parsedContactsToAttendees
        };
        const result = await onUpdate(newEvent);
        if (result) {
          enqueueSnackbar(t("calendarPage.form.eventUpdatedSuccesfully"), { variant: "success" });
        } else {
          enqueueSnackbar(t("calendarPage.form.eventOperationFailure"), { variant: "error" });
        }
        resetForm();
        onCancel();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } =
    formik;

  const handleDelete = async () => {
    if (!event.id) return;
    try {
      onCancel();
      onDelete(event.id);
      enqueueSnackbar("Delete event success", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Delete event failed", { variant: "error" });
    }
  };

  const isDateError = isBefore(new Date(values.end), new Date(values.start));

  const parsedAttendeesToContacts = values.attendees.map(
    ({ firstName, lastName, phoneNumberInternational, ...rest }) => {
      let parsedContact: RealEstateContact = rest;

      if (phoneNumberInternational) {
        parsedContact.id = phoneNumberInternational;
        parsedContact.callerPhoneNumber = phoneNumberInternational;
      }

      if (firstName) {
        parsedContact.callerFirstName = firstName;
      }

      if (lastName) {
        parsedContact.callerLastName = lastName;
      }

      return parsedContact;
    }
  );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent sx={{ pb: 0, overflowY: "unset" }}>
          <TextField
            fullWidth
            label={t("calendarPage.form.title")}
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            sx={{ mb: 3 }}
          />

          <Stack sx={{ mb: 2 }} direction="row" gap={2}>
            <MobileDatePicker
              label={t("calendarPage.form.startDay")}
              minDate={new Date()}
              inputFormat="dd/MM/yyyy"
              value={values.start}
              onChange={(date) => {
                setFieldValue("start", date);
                if (!touched.end && values.end) {
                  const newEnd = moment(date)
                    .add(values.end.getTime() - values.start.getTime(), "ms")
                    .toDate();
                  setFieldValue("end", newEnd);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 112 }} variant="standard" />
              )}
            />
            <MobileTimePicker
              label={t("calendarPage.form.startTime")}
              inputFormat="HH:mm"
              minutesStep={5}
              value={values.start}
              onChange={(date) => {
                setFieldValue("start", date);
                if (!touched.end && values.end) {
                  const newEnd = moment(date)
                    .add(values.end.getTime() - values.start.getTime(), "ms")
                    .toDate();
                  setFieldValue("end", newEnd);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 80 }} variant="standard" />
              )}
              ampm={false}
              ampmInClock={false}
            />
          </Stack>
          <Stack sx={{ mb: 3 }} direction="row" gap={2}>
            <MobileDatePicker
              label={t("calendarPage.form.endDay")}
              minDate={new Date()}
              inputFormat="dd/MM/yyyy"
              value={values.end}
              onChange={(date) => {
                setFieldValue("end", date);
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 112 }} variant="standard" />
              )}
            />
            <MobileTimePicker
              label={t("calendarPage.form.endTime")}
              inputFormat="HH:mm"
              minutesStep={5}
              value={values.end}
              onChange={(date) => {
                setFieldValue("end", date);
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 80 }} variant="standard" />
              )}
              ampm={false}
              ampmInClock={false}
            />
          </Stack>
          <Stack gap={2}>
            <NewMultipleContactPicker
              selectedContacts={parsedAttendeesToContacts}
              setSelectedContacts={(newContacts) => setFieldValue("attendees", newContacts)}
            />
            <TextField
              fullWidth
              multiline
              maxRows={4}
              label={t("calendarPage.form.description")}
              {...getFieldProps("description")}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
            <ColorSinglePicker {...getFieldProps("textColor")} colors={COLOR_OPTIONS} />
          </Stack>
        </DialogContent>

        <DialogActions>
          {!isCreating && (
            <Tooltip title="Delete Event">
              <IconButton onClick={handleDelete}>
                <Icon icon={trash2Fill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button type="button" variant="outlined" color="inherit" onClick={onCancel}>
            {t("general.cancel")}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isDateError || Object.keys(errors).length > 0}
            loading={isSubmitting}
            loadingIndicator="Loading..."
          >
            {t("calendarPage.form.approveButton")}
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
