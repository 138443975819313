export function moveArrayItemIndex<T>(array: T[], fromIndex: number, toIndex: number): T[] {
  const newArray = array.slice();

  newArray.splice(toIndex, 0, ...newArray.splice(fromIndex, 1));

  return newArray;
}

export function stringToColor(string: string): string {
  let i: number;
  let hash = 0;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(name: string | null) {
  const trimmedName = name?.trim();
  const splittedName = trimmedName?.split(" ", 2).filter(Boolean);

  let parsedName: string;

  if (splittedName && splittedName.length) {
    const firstName = splittedName[0][0];

    if (splittedName.length > 1) {
      const lastName = splittedName[1][0];
      parsedName = `${firstName}${lastName}`.toUpperCase();
    } else parsedName = firstName.toUpperCase();
  }

  return {
    ...(parsedName && { sx: { bgcolor: stringToColor(name) }, children: parsedName })
  };
}
